import clsx from "clsx"
import React, { CSSProperties, PropsWithChildren } from "react"
import { makeStyles, Theme, Typography } from "@material-ui/core"
import { StrictOmit } from "shared/Types/helperTypes"

type HeadingProps = Readonly<
    PropsWithChildren<{
        className?: string
        marginTop?: CSSProperties["marginTop"]
        marginBottom?: CSSProperties["marginBottom"]
    }>
>

type StyleProps = StrictOmit<HeadingProps, "children" | "className">

const useStyles = makeStyles((theme: Theme) => ({
    heading: (props: StyleProps) => ({
        color: "#334155",
        fontWeight: 700,
        lineHeight: 1,
        marginTop: props.marginTop,
        marginBottom: props.marginBottom,
    }),
}))

export function Heading(props: HeadingProps) {
    const classes = useStyles(props)

    return (
        <Typography variant="h5" className={clsx(props.className, classes.heading)}>
            {props.children}
        </Typography>
    )
}

export default Heading
