import React, { useEffect } from "react"
import { Localized } from "@fluent/react"
import { NavLink } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { makeStyles, Paper, Badge, BottomNavigationAction } from "@material-ui/core"
import { faHome, faBell, faReceipt, faUser } from "@fortawesome/pro-light-svg-icons"
import { getNotificationCount } from "shared/Modules/Notification/notificationActions"
import { SafeArea } from "shared/Components/Skeleton/SafeArea"
import { WellknownHeight } from "shared/Helpers/constants"
import { IStoreState } from "mobile/Reducers/rootReducer"

const useStyles = makeStyles((theme) => ({
    nav: {
        zIndex: 999,
        backgroundColor: theme.palette.background.paper,
        height: WellknownHeight.BOTTOM_NAV_BAR,
        position: "fixed",
        bottom: 0,
        width: "100%",
    },
    grid: {
        display: "grid",
        gridTemplateRows: "[myrow] 100%",
        gridTemplateColumns: "[home] 1fr [receipts] 1fr [notifications] 1fr [me] 1fr",
        height: "100%",
        width: "100%",
    },
    home: {
        gridRow: "myrow",
        gridColumn: "home / span 1",
    },
    receipts: {
        gridRow: "myrow",
        gridColumn: "receipts / span 1",
    },
    notifications: {
        gridRow: "myrow",
        gridColumn: "notifications / span 1",
    },
    me: {
        gridRow: "myrow",
        gridColumn: "me / span 1",
    },
    action: {
        minWidth: 0,
        textAlign: "center",
    },
    button: {
        height: "100%",
        margin: "0 auto",
    },
    icon: {
        height: 25,
        marginBottom: 3,
    },
    activeButton: {
        color: theme.palette.primary.main,
    },
}))

export default function BottomNavBar() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const notificationCount = useSelector((store: IStoreState) => store.notificationCount.countNotifications)
    const todaysOrderCount = useSelector((store: IStoreState) => store.todaysOrderCount.count)

    useEffect(() => {
        dispatch(getNotificationCount())
    }, [])

    return (
        <SafeArea bottom method="padding">
            <Paper elevation={3} className={classes.nav}>
                <div className={classes.grid}>
                    <div className={`${classes.home} ${classes.action}`}>
                        <Localized id="bottom-navigation-home" attrs={{ label: true }}>
                            <BottomNavigationAction
                                component={NavLink}
                                to="/"
                                exact
                                icon={<FontAwesomeIcon size="lg" icon={faHome} className={classes.icon} />}
                                label="Forside"
                                showLabel
                                className={classes.button}
                                activeClassName={classes.activeButton}
                            />
                        </Localized>
                    </div>
                    <div className={`${classes.receipts} ${classes.action}`}>
                        <Localized id="bottom-navigation-todays-purchases" attrs={{ label: true }}>
                            <BottomNavigationAction
                                component={NavLink}
                                to="/todays-orders"
                                exact
                                icon={
                                    <Badge
                                        anchorOrigin={{ vertical: "top", horizontal: "left" }}
                                        invisible={!todaysOrderCount}
                                        variant="dot"
                                        color="primary"
                                    >
                                        <FontAwesomeIcon size="lg" icon={faReceipt} className={classes.icon} />
                                    </Badge>
                                }
                                label="Kvittering"
                                showLabel
                                className={classes.button}
                                activeClassName={classes.activeButton}
                            />
                        </Localized>
                    </div>
                    <div className={`${classes.notifications} ${classes.action}`}>
                        <Localized id="bottom-navigation-messages" attrs={{ label: true }}>
                            <BottomNavigationAction
                                label="Notifikation"
                                component={NavLink}
                                to="/notifications"
                                icon={
                                    <Badge
                                        anchorOrigin={{ vertical: "top", horizontal: "left" }}
                                        invisible={!notificationCount}
                                        variant="dot"
                                        color="primary"
                                    >
                                        <FontAwesomeIcon size="lg" icon={faBell} className={classes.icon} />
                                    </Badge>
                                }
                                showLabel
                                activeClassName={classes.activeButton}
                                className={classes.button}
                            />
                        </Localized>
                    </div>
                    <div className={`${classes.me} ${classes.action}`}>
                        <Localized id="bottom-navigation-me" attrs={{ label: true }}>
                            <BottomNavigationAction
                                component={NavLink}
                                to="/me"
                                icon={<FontAwesomeIcon size="lg" icon={faUser} className={classes.icon} />}
                                label="Mig"
                                showLabel
                                activeClassName={classes.activeButton}
                                className={classes.button}
                            />
                        </Localized>
                    </div>
                </div>
            </Paper>
        </SafeArea>
    )
}
