import React from "react"
import { Grid, makeStyles, Typography } from "@material-ui/core"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import Screen from "shared/Components/Skeleton/Screen"

const useStyles = makeStyles((theme) => ({
    wrap: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: "100%",
    },
    container: {
        display: "flex",
        flexWrap: "nowrap",
        flexDirection: "column",
        gap: "2rem",
        color: "rgba(0, 0, 0, 0.87)",
        textAlign: "center",
        maxWidth: "14.5rem",
    },
}))

export function PaymentWrongClient() {
    const classes = useStyles()

    return (
        <Screen name="wrong-client" fitPage fullHeight>
            <Grid className={classes.wrap}>
                <Grid className={classes.container}>
                    <LocalizedStrict id="payment-wrong-client-title">
                        <Typography variant="h5">Oops, wrong client</Typography>
                    </LocalizedStrict>
                    <LocalizedStrict id="payment-wrong-client-message-one">
                        <Typography variant="body2">
                            You are in the browser, but you made your purchase in the app.
                        </Typography>
                    </LocalizedStrict>
                    <LocalizedStrict id="payment-wrong-client-message-two">
                        <Typography variant="body2">Please switch back to your app to view your orders.</Typography>
                    </LocalizedStrict>
                </Grid>
            </Grid>
        </Screen>
    )
}
