import { DateTime } from "luxon"
import { IMonetaryAmount } from "shared/Types/appTypes"
import { IOrdersSummary } from "./ordersSummaryApi"

export enum OrdersPeriod {
    LAST_WEEK = "LAST_WEEK",
    LAST_MONTH = "LAST_MONTH",
    LAST_YEAR = "LAST_YEAR",
}

export type GraphPeriodType = keyof typeof OrdersPeriod

export enum ExpensesReportType {
    DAILY = "DAILY",
    MONTHLY = "MONTHLY",
}

export type ReportDetails = Readonly<{
    startDate: string
    endDate: string
    reportTime: DateTime | string
}>

export type DailyDaysTypes = Readonly<{
    date: string
    amount: IMonetaryAmount
}>

interface ILastWeekExpensesReport {
    type: ExpensesReportType.DAILY
    period: OrdersPeriod.LAST_WEEK
    days: DailyDaysTypes[]
}

interface ILastMonthExpensesReport {
    type: ExpensesReportType.DAILY
    period: OrdersPeriod.LAST_MONTH
    days: DailyDaysTypes[]
}

interface ILastYearExpensesReport {
    type: ExpensesReportType.MONTHLY
    period: OrdersPeriod.LAST_YEAR
    months: {
        month: number
        startDate: string
        amount: IMonetaryAmount
    }[]
}

export type IExpensesReport = ILastWeekExpensesReport | ILastMonthExpensesReport | ILastYearExpensesReport

// TODO: REMOVE when the real api is ready
export const expensesChartMockData: Record<GraphPeriodType, IOrdersSummary> = {
    LAST_WEEK: {
        reportDetails: {
            startDate: "2023-01-01",
            endDate: "2023-01-07",
            reportTime: "2023-01-07T15:46:30.081",
        },
        expensesReport: {
            type: ExpensesReportType.DAILY,
            period: OrdersPeriod.LAST_WEEK,
            days: [
                {
                    date: "2023-01-01",
                    amount: {
                        amount: 0,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 0.00",
                    },
                },
                {
                    date: "2023-01-02",
                    amount: {
                        amount: 6550,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 65.50",
                    },
                },
                {
                    date: "2023-01-03",
                    amount: {
                        amount: 4000,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 40.00",
                    },
                },
                {
                    date: "2023-01-04",
                    amount: {
                        amount: 3500,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 35.00",
                    },
                },
                {
                    date: "2023-01-05",
                    amount: {
                        amount: 3500,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 35.00",
                    },
                },
                {
                    date: "2023-01-06",
                    amount: {
                        amount: 3500,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 35.00",
                    },
                },
                {
                    date: "2023-01-07",
                    amount: {
                        amount: 0,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 0.00",
                    },
                },
            ],
        },
    },
    LAST_MONTH: {
        reportDetails: {
            startDate: "2022-12-07",
            endDate: "2023-01-07",
            reportTime: "2023-01-07T15:46:30.081",
        },
        expensesReport: {
            type: ExpensesReportType.DAILY,
            period: OrdersPeriod.LAST_MONTH,
            days: [
                {
                    date: "2022-12-07",
                    amount: {
                        amount: 5000,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 50.00",
                    },
                },
                {
                    date: "2022-12-08",
                    amount: {
                        amount: 6550,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 65.50",
                    },
                },
                {
                    date: "2022-12-09",
                    amount: {
                        amount: 4000,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 40.00",
                    },
                },
                {
                    date: "2022-12-10",
                    amount: {
                        amount: 0,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 0.00",
                    },
                },
                {
                    date: "2022-12-11",
                    amount: {
                        amount: 0,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 0.00",
                    },
                },
                {
                    date: "2022-12-12",
                    amount: {
                        amount: 3500,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 35.00",
                    },
                },
                {
                    date: "2022-12-13",
                    amount: {
                        amount: 0,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 0.00",
                    },
                },
                {
                    date: "2022-12-14",
                    amount: {
                        amount: 0,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 0.00",
                    },
                },
                {
                    date: "2022-12-15",
                    amount: {
                        amount: 0,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 0.00",
                    },
                },
                {
                    date: "2022-12-16",
                    amount: {
                        amount: 0,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 0.00",
                    },
                },
                {
                    date: "2022-12-17",
                    amount: {
                        amount: 0,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 0.00",
                    },
                },
                {
                    date: "2022-12-18",
                    amount: {
                        amount: 0,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 0.00",
                    },
                },
                {
                    date: "2022-12-19",
                    amount: {
                        amount: 0,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 0.00",
                    },
                },
                {
                    date: "2022-12-20",
                    amount: {
                        amount: 0,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 0.00",
                    },
                },
                {
                    date: "2022-12-21",
                    amount: {
                        amount: 0,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 0.00",
                    },
                },
                {
                    date: "2022-12-22",
                    amount: {
                        amount: 0,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 0.00",
                    },
                },
                {
                    date: "2022-12-23",
                    amount: {
                        amount: 0,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 0.00",
                    },
                },
                {
                    date: "2022-12-24",
                    amount: {
                        amount: 0,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 0.00",
                    },
                },
                {
                    date: "2022-12-25",
                    amount: {
                        amount: 0,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 0.00",
                    },
                },
                {
                    date: "2022-12-26",
                    amount: {
                        amount: 0,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 0.00",
                    },
                },
                {
                    date: "2022-12-27",
                    amount: {
                        amount: 0,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 0.00",
                    },
                },
                {
                    date: "2022-12-28",
                    amount: {
                        amount: 0,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 0.00",
                    },
                },
                {
                    date: "2022-12-29",
                    amount: {
                        amount: 0,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 0.00",
                    },
                },
                {
                    date: "2022-12-30",
                    amount: {
                        amount: 0,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 0.00",
                    },
                },
                {
                    date: "2022-12-31",
                    amount: {
                        amount: 0,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 0.00",
                    },
                },
                {
                    date: "2023-01-01",
                    amount: {
                        amount: 0,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 0.00",
                    },
                },
                {
                    date: "2023-01-02",
                    amount: {
                        amount: 0,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 0.00",
                    },
                },
                {
                    date: "2023-01-03",
                    amount: {
                        amount: 0,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 0.00",
                    },
                },
                {
                    date: "2023-01-04",
                    amount: {
                        amount: 0,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 0.00",
                    },
                },
                {
                    date: "2023-01-05",
                    amount: {
                        amount: 0,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 0.00",
                    },
                },
                {
                    date: "2023-01-06",
                    amount: {
                        amount: 0,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 0.00",
                    },
                },
            ],
        },
    },
    LAST_YEAR: {
        reportDetails: {
            startDate: "2022-02-01",
            endDate: "2023-01-07",
            reportTime: "2023-01-07T15:46:30.081",
        },
        expensesReport: {
            type: ExpensesReportType.MONTHLY,
            period: OrdersPeriod.LAST_YEAR,
            months: [
                {
                    month: 2,
                    startDate: "2022-02-01",
                    amount: {
                        amount: 165550,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 1655.50",
                    },
                },
                {
                    month: 3,
                    startDate: "2022-03-01",
                    amount: {
                        amount: 165550,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 1655.50",
                    },
                },
                {
                    month: 4,
                    startDate: "2022-04-01",
                    amount: {
                        amount: 165550,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 1655.50",
                    },
                },
                {
                    month: 5,
                    startDate: "2022-05-01",
                    amount: {
                        amount: 165550,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 1655.50",
                    },
                },
                {
                    month: 6,
                    startDate: "2022-06-01",
                    amount: {
                        amount: 165550,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 1655.50",
                    },
                },
                {
                    month: 7,
                    startDate: "2022-07-01",
                    amount: {
                        amount: 165550,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 1655.50",
                    },
                },
                {
                    month: 8,
                    startDate: "2022-08-01",
                    amount: {
                        amount: 165550,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 1655.50",
                    },
                },
                {
                    month: 9,
                    startDate: "2022-09-01",
                    amount: {
                        amount: 165550,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 1655.50",
                    },
                },
                {
                    month: 10,
                    startDate: "2022-10-01",
                    amount: {
                        amount: 165550,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 1655.50",
                    },
                },
                {
                    month: 11,
                    startDate: "2022-11-01",
                    amount: {
                        amount: 165550,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 1655.50",
                    },
                },
                {
                    month: 12,
                    startDate: "2022-12-01",
                    amount: {
                        amount: 165550,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 1655.50",
                    },
                },
                {
                    month: 1,
                    startDate: "2023-01-01",
                    amount: {
                        amount: 15000,
                        scale: 2,
                        currency: "DKK",
                        formatted: "DKK 150.00",
                    },
                },
            ],
        },
    },
}

export const getGraphOrdersMockData = (period: GraphPeriodType) => expensesChartMockData[period]
